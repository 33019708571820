<template>
  <div>
    <ValidatedTypeahead
      :labelValue="$t('app.admin.newsFeeds.place')"
      name="place"
      v-model="innerModel.place"
      fetchURL="/api/places?keyword={query}"
      :serializer="(i) => i.name"
      rules=""
    />
    <ValidatedInput
      :label="$t('app.admin.newsFeeds.publisher')"
      name="publisher"
      v-model="innerModel.publisher"
      rules="required|max:255"
    />
    <ValidatedInput
      :label="$t('app.admin.newsFeeds.url')"
      name="url"
      v-model="innerModel.url"
      rules="required|url"
    />
    <ValidatedSelect
      :label="$t('app.admin.newsFeeds.feedType')"
      name="feed_type"
      v-model="innerModel.feed_type"
      :options="feedOptions"
      rules="required"
    />
    <ValidatedInput
      :label="$t('app.admin.newsFeeds.titleFilter')"
      :description="$t('app.admin.newsFeeds.titleFilterDescription')"
      name="title_filter"
      v-model="innerModel.title_filter"
      rules="max:255"
    />
    <ValidatedInput
      :label="$t('app.admin.newsFeeds.titleSubPattern')"
      :description="$t('app.admin.newsFeeds.titleSubPatternDescription')"
      name="title_sub_pattern"
      v-model="innerModel.title_sub_pattern"
      rules="max:255"
    />
    <ValidatedInput
      :label="$t('app.admin.newsFeeds.titleSubRepl')"
      :description="$t('app.admin.newsFeeds.titleSubReplDescription')"
      name="title_sub_repl"
      v-model="innerModel.title_sub_repl"
      rules="max:255"
    />
  </div>
</template>

<script>
import FormPartMixin from "@/mixins/FormPartMixin.js";
import ValidatedInput from "@/components/ValidatedInput.vue";
import ValidatedSelect from "@/components/ValidatedSelect.vue";
import ValidatedTypeahead from "@/components/ValidatedTypeahead.vue";
import { getLocalizedFeedTypes } from "../common.js";
export default {
  mixins: [FormPartMixin],
  components: { ValidatedInput, ValidatedSelect, ValidatedTypeahead },
  data() {
    return {
      feedOptions: [],
    };
  },
  mounted() {
    this.feedOptions = getLocalizedFeedTypes();
  },
};
</script>
